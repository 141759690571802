/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Color Theme Swatches in Hex */
/* Color Theme Swatches in Hex */
.content-block {
  padding: 12px 40px;
}
@media (max-width: 576px) {
  .content-block {
    padding: 8px 24px;
  }
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0 !important;
  font-family: sommet-rounded, sans-serif;
}
.page-padding {
  padding: 28px 40px;
}
.text-page {
  padding-left: 24px;
}
.static-page {
  max-width: 1000px;
  margin: 0 auto;
}
@media (max-width: 576px) {
  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 17px;
  }
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 13px;
  }
  p,
  li {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .page-padding {
    padding: 16px 24px;
  }
  .text-page {
    padding: 0;
  }
}
.table {
  display: table;
  width: 100%;
}
.table-cell {
  display: table-cell;
}
.co2-card .header {
  font-size: 16px;
  font-weight: bold;
  color: dimgrey;
}
.co2-card .box {
  min-height: 420px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 10px;
}
#map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
svg .gas {
  fill: #d77937;
}
svg .coal {
  fill: #163b4f;
}
svg .oil {
  fill: #5995da;
}
svg .glyph {
  fill: #ffffff;
}
svg .numeric {
  font-family: sans-serif;
}

